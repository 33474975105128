@use '@angular/material' as mat;

@include mat.core();

$primary-color: #006445; // Primary color
$secondary-color: #33836a; // Secondary color
$accent-color: #0eb07f; // Accent color
$background-color: #bfd4cb; // Background color
$shadow-color: #aae0c9; // Shadow color
$error-color: #A62C2B;

$theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$green-palette,
    ),
    typography: (
      brand-family: 'Comic Sans',
      bold-weight: 900,
    ),
    density: (
      scale: -1,
    ),
  )
);

html {
  @include mat.all-component-themes($theme);
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

body {
  margin: 0;
  font-family: sans-serif;
}
.sidenav a {
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 16px;
  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
    color: #000000;
  }
}
.mdc-list-item:hover .mdc-list-item__primary-text, .mdc-list-item:focus .mdc-list-item__primary-text {
  color: #006445 !important;
}
mat-header-cell:first-of-type {
  padding-left: 16px !important;
}
.mat-mdc-row .mdc-data-table__cell {
  padding: 10px 0 10px 16px;
}
.filter-container {
  padding: 0 20px 20px;
  button {
    margin-right: 15px;
  }
  h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
}
.cdk-global-overlay-wrapper {
  min-width: 60%;
  left: 50% !important;
  transform: translateX(-50%);
  .mat-mdc-dialog-panel {
    width: 100% !important;
  }
  .mat-mdc-form-field {
    width: 85% !important;
  }
  .mat-mdc-dialog-container {
    max-height: 90vh;
  }
}
button[type='submit'] {
  background-color: #006445 !important;
  color: #ffffff !important;
  min-height: 45px;
  margin: 20px 0;
  min-width: 200px;
  display: block;
  &.mat-mdc-button-disabled {
    opacity: 0.6;
  }
}
.header {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  h3 {
    margin-left: 10px;
  }
  button {
    margin-right: 10px;
  }
  &.create {
    padding: 0;
  }
}

.ng-star-inserted {
  .mat-mdc-form-field {
    margin-right: 15px;
  }
  .mat-mdc-card {
    padding: 0 15px;
  }
  .top-bar {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    .page-title {
      margin-left: 10px;
      font-weight: bold;
    }
  }
  .mat-mdc-tab-body {
    padding: 10px 12px 20px;
    .mat-mdc-form-field {
      margin-right: 10px;
    }
    button:not(.mdc-icon-button):not(.close-dialog) {
      margin-bottom: 20px;
    }
  }
  form {
    padding-top: 15px;
    button:not(.mdc-icon-button):not(.close-dialog) {
      margin-bottom: 20px;
    }
  }
  .scrollable-card {
    height: calc(100vh - 210px);
    overflow-y: auto;
  }
}

.mat-mdc-cell {
  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.mat-mdc-dialog-title {
  font-family: sans-serif !important;
}

.mat-mdc-row .mat-mdc-cell {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  cursor: pointer;
}
.mat-mdc-row:hover .mat-mdc-cell {
  border-color: currentColor;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mat-mdc-header-row {
  position: sticky;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #000000 !important;
}
.viewport {
  width: 100%;
  height: calc(100vh - 220px);
  @media only screen and (max-width: 767px) {
    height: calc(100vh - 265px);
  }
}
.mat-mdc-dialog-container .mat-mdc-dialog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  &::before {
    display: none;
  }
}
.full-field {
    width: 50%;
    @media only screen and (max-width: 767px) {
        width: 100%;
    }
    &-80 {
        width: 85%;
    }
    &.d-block {
        display: block !important;
    }
}
.no-hover.mdc-list-item:hover .mdc-list-item__primary-text {
    color: #000000 !important;
}
.mdc-snackbar {
    &__surface {
        background-color: #FFFFFF !important;
        color: $error-color !important;
        padding: 0 !important;
    }
    .mat-mdc-snack-bar-label {
        padding: 0 !important;
    }
    .mdc-linear-progress__bar-inner {
        border-color: $error-color !important;
    }
}